import React from 'react';
import { View, Text, StyleSheet, Image, ScrollView, TouchableOpacity, ActivityIndicator,Dimensions } from 'react-native';

const ItemDetails = ({ route }) => {
  const { item } = route.params;
  const imageUrl = item.imageUrl || 'https://www.apple.com/newsroom/images/2024/09/apple-introduces-iphone-16-and-iphone-16-plus/article/Apple-iPhone-16-hero-240909_inline.jpg.large.jpg'; 
  const [isLoading, setIsLoading] = React.useState(true);
  const { width: screenWidth } = Dimensions.get('window');
   {/* Show the item image if available */}
   {item.imageUrl ? (
    <Image source={{ uri: item.imageUrl }} style={styles.itemImage} />
  ) : (
    <Text>No Image Available</Text>
  )}

      {item.imageBase64 ? (
            <Image 
              source={{ uri: `data:image/jpeg;base64,${item.imageBase64}` }} // Format it for Base64
              style={styles.image}
              resizeMode="cover"
            />
          ) : (
            <Text>No image available</Text>
          )}


  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Product Images */}
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <Image 
          source={{ uri: imageUrl }} 
          style={[styles.productImage,{ width: screenWidth}]} 
          onLoadEnd={() => setIsLoading(false)} // Hide loading indicator when image loads
        /> 
        {isLoading && <ActivityIndicator size="large" color="#0000ff" style={styles.loadingIndicator} />} 
        {/* You can add more Image components here if you have multiple image URLs */}
      </ScrollView>

      {/* Product Details */}
      <Text style={styles.title}>  Item No: {item.id}</Text>
          <Text style={styles.description}>  Name: {item.name}</Text>
          <Text style={styles.description}>  Quantity: {item.quantity}</Text>
          <Text style={styles.title}>  Unit Price: {item.unitPrice}</Text>
          
      {/* Buy Button */}
      <TouchableOpacity style={styles.buyButton}>
        <Text style={styles.buyButtonText}>Buy</Text>
        <Text style={styles.priceText}>USD {item.unitPrice || 'N/A'}</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 0,
  },
  productImage: {
    //width: 200,
    height: 600,
    marginRight: 10,
    //resizeMode: 'contain'
     
  },
  loadingIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  sizeContainer: {
    color: 'white',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 10,
  },
  sizeButton: {
    backgroundColor: '#eee',
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 20,
    marginRight: 10,
    marginBottom: 10,
  },
  selectedSizeButton: {
    backgroundColor: 'black', 
  },
  sizeText: {
    fontSize: 16,
    color: '#333',
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginVertical: 10,
  },
  buyButton: {
    backgroundColor: 'black',
    padding: 15,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10,
  },
  buyButtonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  priceText: {
    color: 'white',
    fontSize: 18,
  },
});

export default ItemDetails;