import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, ImageBackground, Alert } from 'react-native';

// Credentials and API URLs
const client_id = '0e9b6639-eeba-4a00-b29e-c9d838b6b54c';  // Replace with your Client ID
const client_secret = 'RfV8Q~FQnwZOVkq2sqqgYhGlnXVFk_8yipsfNcLH';  // Replace with your Client Secret
const token_url = 'https://login.microsoftonline.com/cb92009c-0f4a-46c6-a253-654081cb6aca/oauth2/v2.0/token';
const scope = 'https://api.businesscentral.dynamics.com/.default';
const api_url = 'https://api.businesscentral.dynamics.com/v2.0/cb92009c-0f4a-46c6-a253-654081cb6aca/D365BCTEST/api/v2.0/companies(f65aecdd-ef6e-ef11-a677-6045bdc893a7)/employees';  // Replace with the Business Central API endpoint

const WelcomeScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      // Get access token from Microsoft Identity platform
      const tokenResponse = await fetch(token_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `client_id=${client_id}&scope=${scope}&client_secret=${client_secret}&grant_type=client_credentials`,
      });

      const tokenData = await tokenResponse.json();
      const accessToken = tokenData.access_token;

      console.log('Access Token:', accessToken);

      // Use the access token to call Business Central API and get employee data
      const response = await fetch(api_url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      const employeeData = await response.json();
      console.log('Employee data:', employeeData);

      // Match the entered email with the employee email in Business Central
      const matchingEmployee = employeeData.value.find(emp => emp.email === email);

      if (matchingEmployee) {
        // Check if the entered password matches the employee's phone number
        if (matchingEmployee.phoneNumber === password) {
          console.log('Login successful');
          // Navigate to VendorList screen
          navigation.navigate('HomeScreen'),{employeeData: employeeData.value[0]};

        } else {
          Alert.alert('Error', 'Incorrect password. Please try again.');
        }
      } else {
        Alert.alert('Error', 'No matching employee found.');
      }

    } catch (error) {
      console.error('Login failed', error);
      Alert.alert('Login failed', 'Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ImageBackground
      source={{ uri: 'https://images.pexels.com/photos/12507496/pexels-photo-12507496.jpeg?' }}
      style={styles.container}
      resizeMode="cover"
    >
      <View style={styles.content}>
        <Text style={styles.heading}>Welcome {'\n'}Back</Text>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            placeholder="Enter email"
            value={email}
            onChangeText={setEmail}
            keyboardType="email-address"
          />
          <TextInput
            style={styles.input}
            placeholder="Password (Phone Number)"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
          />
          <TouchableOpacity>
            <Text style={styles.forgotPassword}>Forgot password?</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity style={styles.loginButton} onPress={handleLogin} disabled={loading}>
          <Text style={styles.loginButtonText}>{loading ? 'Logging in...' : 'Log in'}</Text>
        </TouchableOpacity>
      
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    padding: 30,
    justifyContent: 'center',
    borderRadius: 10,
  },
  inputContainer: {
    marginBottom: 20,
  },
  input: {
    backgroundColor: '#F0F0F0',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
  },
  forgotPassword: {
    color: '#0080FF',
    textAlign: 'right',
    marginBottom: 20,
  },
  loginButton: {
    backgroundColor: '#0080FF',
    borderRadius: 10,
    padding: 15,
    alignItems: 'center',
  },
  loginButtonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  link: {
    color: '#0080FF',
    textAlign: 'center',
    marginTop: 20,
  },
  heading: {
    fontSize: 40,
    fontWeight: 'bold',
    color: '#0080FF',
    marginBottom: 30,
    textAlign: 'left',
    fontFamily: 'Product Sans',
  },
});

export default WelcomeScreen;
