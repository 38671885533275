// VendorList.js
import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, ActivityIndicator, StyleSheet, SafeAreaView, TouchableOpacity, RefreshControl } from 'react-native';
import axios from 'axios';

const client_id = '0e9b6639-eeba-4a00-b29e-c9d838b6b54c';  // Replace with your Client ID
const client_secret = 'RfV8Q~FQnwZOVkq2sqqgYhGlnXVFk_8yipsfNcLH';  // Replace with your Client Secret
const token_url = 'https://login.microsoftonline.com/cb92009c-0f4a-46c6-a253-654081cb6aca/oauth2/v2.0/token';
const scope = 'https://api.businesscentral.dynamics.com/.default';
const api_url = 'https://api.businesscentral.dynamics.com/v2.0/cb92009c-0f4a-46c6-a253-654081cb6aca/D365BCTEST/api/v2.0/companies(f65aecdd-ef6e-ef11-a677-6045bdc893a7)/vendors';

const VendorList = ({ navigation }) => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const getAccessToken = async () => {
    const data = new URLSearchParams({
      grant_type: 'client_credentials',
      client_id,
      client_secret,
      scope,
    });

    const response = await axios.post(token_url, data);
    return response.data.access_token;
  };

  const fetchVendors = async () => {
    setRefreshing(true); // Start refreshing
    try {
      const accessToken = await getAccessToken();
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setVendors(response.data.value);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setRefreshing(false); // Stop refreshing
    }
  };

  useEffect(() => {
    fetchVendors(); // Fetch data on initial load
  }, []);

  const handleVendorPress = async (vendor) => {
    navigation.navigate('VendorDetails', { vendor });
  };

  const getColor = (label) => {
    switch (label) {
      case 'Email':
        return '#007AFF'; // Blue for email
      case 'Phone':
        return '#007AFF'; // Blue for phone
      case 'Balance LCY':
        return vendors.balance < 0 ? '#D50000' : '#4CAF50'; // Red for negative balance, green for positive
      default:
        return '#555'; // Default color
    }
  };

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  if (error) {
    return <Text style={styles.errorText}>Error: {error}</Text>;
  }

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={vendors}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => handleVendorPress(item)}>
            <View style={styles.item}>
              <Text style={styles.vendorText}>Name: {item.displayName || 'N/A'}</Text>
              <Text style={styles.vendorText}>Email: {item.email || 'N/A'}</Text>
              <Text style={styles.vendorText}>Phone: {item.phoneNumber || 'N/A'}</Text>
              <Text style={styles.vendorText}>Balance LCY :{item.balance.toFixed(2)|| 'N/A'}</Text>
            </View>
          </TouchableOpacity>
        )}
        refreshControl={
          <RefreshControl
            refreshing={refreshing} // Controlled by state
            onRefresh={fetchVendors} // Trigger fetch on pull down
          />
        }
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingTop: 10,
  },
  item: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  vendorText: {
    fontSize: 16,
    marginBottom: 5,
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    margin: 20,
  },
});

export default VendorList;
