// VendorDetails.js
import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';

const VendorDetails = ({ route }) => {
  const { vendor } = route.params;  // Get vendor details passed through navigation

  const getColor = (label) => {
    switch (label) {
      case 'Phone':
      case 'Email':
        return '#007AFF'; // Blue for contact info
      case 'Tax Registration Number':
        return '#D50000'; // Red for tax-related info
      case 'Balance LCY':
        return vendor.balance < 0 ? '#D50000' : '#4CAF50'; // Red for negative balance, green for positive
      default:
        return '#555'; // Default color
    }
  };

  const renderRow = (label, value) => (
    <View style={styles.row} key={label}>
      <Text style={[styles.label, { color: getColor(label) }]}>{label}:</Text>
      <Text style={[styles.value, { color: getColor(label) }]}>{value || 'N/A'}</Text>
    </View>
  );

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {renderRow('Vendor Number', vendor.number)}
      {renderRow('Name', vendor.displayName)}
      {renderRow('Address Line 1', vendor.addressLine1)}
      {renderRow('Address Line 2', vendor.addressLine2)}
      {renderRow('City', vendor.city)}
      {renderRow('State', vendor.state)}
      {renderRow('Country', vendor.country)}
      {renderRow('Postal Code', vendor.postalCode)}
      {renderRow('Phone', vendor.phoneNumber)}
      {renderRow('Email', vendor.email)}
      {renderRow('Website', vendor.website)}
      {renderRow('Tax Registration Number', vendor.taxRegistrationNumber)}
      {renderRow('Currency Code', vendor.currencyCode)}
      {renderRow('Tax Liable', vendor.taxLiable ? 'Yes' : 'No')}
      {renderRow('Balance LCY', vendor.balance.toFixed(2))}
      {renderRow('Last Modified', new Date(vendor.lastModifiedDateTime).toLocaleString())}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    justifyContent: 'flex-start',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    paddingBottom: 10,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    flex: 1,   // Adjust label width for alignment
  },
  value: {
    fontSize: 16,
    flex: 2,   // Adjust value width for alignment
    textAlign: 'right',  // Align values to the right for neatness
  },
});

export default VendorDetails;
