import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, StatusBar } from 'react-native';

const HomeScreen = ({ navigation }) => {
  return (
    <ImageBackground 
      source={{ uri: 'https://images.pexels.com/photos/12507496/pexels-photo-12507496.jpeg' }} 
      style={styles.background}
      resizeMode="cover"
    >
      {/* StatusBar: Customize the appearance */}
      <StatusBar backgroundColor="transparent" barStyle="light-content" translucent={true} />

      {/* Header section */}
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Welcome to the App</Text>
      </View>

      {/* Navigation Options */}
      <View style={styles.optionsContainer}>
        {/* Vendor List Button */}
        <TouchableOpacity onPress={() => navigation.navigate('VendorList')}>
          <ImageBackground
            source={{ uri: 'https://images.pexels.com/photos/1679719/pexels-photo-1679719.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' }} // Use image as button background
            style={styles.button}
            imageStyle={styles.buttonImage}
          >
            <Text style={styles.buttonText}>Vendor List</Text>
          </ImageBackground>
        </TouchableOpacity>

        {/* Item List Button */}
        <TouchableOpacity onPress={() => navigation.navigate('ItemList')}>
          <ImageBackground
            source={{ uri: 'https://images.pexels.com/photos/1679719/pexels-photo-1679719.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' }} // Same image as button background
            style={styles.button}
            imageStyle={styles.buttonImage}
          >
            <Text style={styles.buttonText}>Item List</Text>
          </ImageBackground>
        </TouchableOpacity>

        {/* Item Test Button */}
        <TouchableOpacity onPress={() => navigation.navigate('ItemImageDisplay')}>
          <ImageBackground
            source={{ uri: 'https://images.pexels.com/photos/1679719/pexels-photo-1679719.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' }} // Same image
            style={styles.button}
            imageStyle={styles.buttonImage}
          >
            <Text style={styles.buttonText}>Item Test</Text>
          </ImageBackground>
        </TouchableOpacity>

        {/* Other Option Button */}
        <TouchableOpacity onPress={() => navigation.navigate('OtherOption')}>
          <ImageBackground
            source={{ uri: 'https://images.pexels.com/photos/1679719/pexels-photo-1679719.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' }} // Same image
            style={styles.button}
            imageStyle={styles.buttonImage}
          >
            <Text style={styles.buttonText}>Other Option</Text>
          </ImageBackground>
        </TouchableOpacity>

        {/* Another Option Button */}
        <TouchableOpacity onPress={() => navigation.navigate('AnotherOption')}>
          <ImageBackground
            source={{ uri: 'https://images.pexels.com/photos/1679719/pexels-photo-1679719.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' }} // Same image
            style={styles.button}
            imageStyle={styles.buttonImage}
          >
            <Text style={styles.buttonText}>Another Option</Text>
          </ImageBackground>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
  },
  header: {
    paddingVertical: 30,
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#CCD0CF',
  },
  optionsContainer: {
    flex: 1,
    justifyContent: 'space-around',
    paddingHorizontal: 20,
  },
  button: {
    width: '100%',
    height: 160,
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    overflow: 'hidden', // Ensures the image stays within the rounded borders
  },
  buttonImage: {
    borderRadius: 20, // Make the image fit the rounded corners
  },
  buttonText: {
    color: '#FFFFFF', // White text for contrast
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slight black overlay for text readability
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
  },
});

export default HomeScreen;
