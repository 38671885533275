// App.js
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import WelcomeScreen from './WelcomeScreen'; // Ensure correct path
import VendorList from './VendorList'; // Ensure correct path
import VendorDetails from './VendorDetails'; // Ensure correct path
import ItemList from './ItemList';
import ItemDetails from './ItemDetails';
import ItemImageDisplay from './ItemImageDisplay'; 
import HomeScreen from './HomeScreen.js'; 

const Stack = createStackNavigator();

const App = () => {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Welcome">
        <Stack.Screen 
          name="Welcome" 
          component={WelcomeScreen} 
          //options={{ title: '' }} // Optional: Set header title
          options={{ headerShown: false }} 
        />
        <Stack.Screen 
          name="VendorList" 
          component={VendorList} 
          options={{ title: 'Vendor List' }} // Optional: Set header title
        />
        <Stack.Screen 
          name="VendorDetails" 
          component={VendorDetails} 
          options={{ title: 'Vendor Details' }} // Optional: Set header title
        />
        <Stack.Screen 
          name="ItemList" 
          component={ItemList} 
          options={{ title: 'Item List' }} // Optional: Set header title
        />
        <Stack.Screen 
          name="ItemDetails" 
          component={ItemDetails} 
          //options={{ title: 'Item Details' }} 
          options={{ headerShown: false }} 
        />

        <Stack.Screen
        name='ItemImageDisplay'
        component={ItemImageDisplay}
        options={{ title: 'Item Image Display' }}

        
        />

        <Stack.Screen
        name='HomeScreen'
        component={HomeScreen}
        //options={{ title: 'Home Screen' }}
        options={{ headerShown: false }}
        
        />
        
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;
