import React, { useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet, ActivityIndicator } from 'react-native';
import axios from 'axios';

// Replace with your Business Central API URL
const BASE_URL = `https://api.businesscentral.dynamics.com/v2.0/cb92009c-0f4a-46c6-a253-654081cb6aca/D365BCTEST/ODataV4/Company('CRONUS%20International%20Ltd.')/OdataItemPic`;

const ItemImageDisplay = () => {
  const [itemData, setItemData] = useState(null); // Changed to null for clarity
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [base64Image, setBase64Image] = useState(''); // State to store the Base64 image

  // Function to fetch the access token
  const fetchAccessToken = async () => {
    const tokenUrl = 'https://login.microsoftonline.com/cb92009c-0f4a-46c6-a253-654081cb6aca/oauth2/v2.0/token'; // Replace with your token URL
    const client_id = '0e9b6639-eeba-4a00-b29e-c9d838b6b54c';  // Your Client ID
    const client_secret = 'RfV8Q~FQnwZOVkq2sqqgYhGlnXVFk_8yipsfNcLH';  // Your Client Secret

    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', client_id);
    params.append('client_secret', client_secret);
    params.append('scope', 'https://api.businesscentral.dynamics.com/.default');

    try {
      const response = await axios.post(tokenUrl, params);
      return response.data.access_token;
    } catch (err) {
      console.error('Error fetching access token:', err);
      setError('Unable to fetch access token');
    }
  };

  // Function to fetch item data
  const fetchItemData = async () => {
    const token = await fetchAccessToken();
    if (!token) return;

    try {
      const response = await axios.get(BASE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Log the entire response to inspect its structure in JSON format
      console.log('API Response:', JSON.stringify(response.data, null, 2));

      // Check if the response has the expected structure
      const fetchedData = response.data.value || [response.data]; // Wrap in an array if not present

      // Save the Base64 image to state (assuming item.Picture is the Base64 string)
      if (fetchedData.length > 0 && fetchedData[0].picture) {
        const imageBase64 = fetchedData[0].picture; // Adjust based on your actual API response structure
        setBase64Image(imageBase64);
        console.log('Base64 Image:', imageBase64); // Log the Base64 string
      }

      setItemData(fetchedData);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching item data:', err);
      setError('Unable to fetch item data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  if (error) {
    return <Text style={styles.errorText}>{error}</Text>;
  }

  return (
    <View style={styles.container}>
      {itemData && itemData.map((item) => (
        <View key={item.ItemId} style={styles.itemContainer}>
          <Text style={styles.itemName}>{item.DisplayName}</Text>
          {base64Image ? (
            <Image 
              source={{ uri: `data:image/jpeg;base64,${base64Image}` }} // Format it for Base64
              style={styles.image}
              resizeMode="cover"
            />
          ) : (
            <Text>No image available</Text>
          )}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  itemContainer: {
    marginBottom: 16,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 8,
  },
  itemName: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  image: {
    width: '100%',
    height: 600,
    borderRadius: 8,
    marginTop: 8,
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginTop: 20,
  },
});

export default ItemImageDisplay;
